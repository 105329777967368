import compressImage from "./compressImage"

export const imageToURL = files =>
	Promise.all(
		files?.map(
			file =>
				new Promise((resolve, reject) => {
					const reader = new FileReader()
					reader.onload = e =>
						resolve({
							name: file.name,
							url: e.target.result,
							file
						})
					reader.readAsDataURL(file)
				})
		)
	)

export const selectImage = ({ imageConfig, multiple = false }) =>
	new Promise((resolve, reject) => {
		const input = document.createElement("input")
		input.type = "file"
		input.multiple = multiple
		input.accept = "image/*"
		input.addEventListener("change", async e => {
			if (!e.target?.files?.[0]) return resolve()
			const compressedFiles = []
			for (const file of Array.from(e.target?.files)) {
				const compressedFile = await compressImage(file, imageConfig)
				compressedFiles.push(compressedFile)
			}

			const urls = await imageToURL(compressedFiles)
			resolve(urls)
		})
		input.click()
	})
