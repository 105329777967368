import Compressor from "compressorjs"

export const imageConfigs = {
	category: { maxWidth: 64, maxSize: 200 * 1024 },
	gallery: { maxWidth: 1024, maxSize: 600 * 1024 },
	blogCover: { maxWidth: 1250, maxSize: 600 * 1024 }
}

const qualityMap = [
	{ ratio: 0, quality: 1 },
	{ ratio: 46.41, quality: 0.8 },
	{ ratio: 67.99, quality: 0.6 },
	{ ratio: 76.18, quality: 0.4 },
	{ ratio: 83.9, quality: 0.2 },
	{ ratio: 94.72, quality: 0 }
]

export default async function compressImage(image, config) {
	if (image.size <= config.maxSize) return image

	// level 1 compress # width based
	let compressedBlob = await new Promise((resolve, reject) => {
		new Compressor(image, {
			maxWidth: config.maxWidth,
			mimeType: "image/jpeg",
			success: resolve,
			error: reject
		})
	})

	// level 2 compress # quality based
	if (compressedBlob.size > config.maxSize) {
		const compressionRatio = (1 - config.maxSize / image.size) * 100
		const bestQuality = qualityMap.find(q => q.ratio >= compressionRatio)?.quality || 0

		console.log(config)

		compressedBlob = await new Promise((resolve, reject) => {
			new Compressor(compressedBlob, {
				quality: bestQuality,
				mimeType: "image/jpeg",
				success: resolve,
				error: reject
			})
		})
	}

	return new File([compressedBlob], image.name, {
		type: compressedBlob.type
	})
}
