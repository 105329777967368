import React, { useEffect, useState } from "react"
import "./styles.css"
import { useNavigate, useParams } from "react-router-dom"
import { RiDeleteBinLine } from "react-icons/ri"
import { FaRegImage } from "react-icons/fa6"
import Prompt from "../../components/Prompt"
import axios from "axios"
import Spinner, { spinnerTypes } from "../../components/Spinner"
import TableTitle from "../../components/TableTitle"
import { LiaUndoAltSolid } from "react-icons/lia"
import { MdClose } from "react-icons/md"

import ReactQuill, { Quill } from "react-quill"
import "react-quill/dist/quill.snow.css"
import { selectImage } from "../../lib/utils"
import { imageConfigs } from "../../lib/compressImage"

function imageHandler() {
	var range = this.quill.getSelection()
	var value = prompt("Insert the valid image URL")
	if (!value) return
	this.quill.insertEmbed(range.index, "image", value, Quill.sources.USER)
}

const quillConfig = {
	toolbar: {
		container: [
			["bold", "italic", "underline", "strike"],
			["blockquote"],
			["link", "image", "video"],

			[{ list: "ordered" }, { list: "bullet" }],
			[{ script: "sub" }, { script: "super" }],
			[{ indent: "-1" }, { indent: "+1" }],

			[{ size: ["small", false, "large"] }],
			[{ header: [2, 3, false] }],
			[{ align: [] }],
			["clean"]
		],
		handlers: {
			image: imageHandler
		}
	}
}

const BlogForm = () => {
	const navigate = useNavigate()
	const { blogId } = useParams()

	const [coverState, setCoverState] = useState({})

	const [blogData, setBlogData] = useState()
	const [promptState, setPromptState] = useState()
	const [loading, setLoading] = useState()

	const autoResize = elems => {
		const scrollY = window.scrollY
		elems.forEach(elem => {
			elem.style.height = "auto"
			elem.style.height = elem.scrollHeight + "px"
		})
		window.scrollTo({ top: scrollY })
	}

	useEffect(() => {
		console.log(blogId)
		if (!blogId) return
		;(async () => {
			setLoading("spinner")
			try {
				const response = await axios.get(`/blog/${blogId}`)
				if (response.data) {
					setBlogData(response.data)
					if (response.data.cover)
						setCoverState(prev => ({
							default: {
								uploaded: true,
								url: `${axios.defaults.baseURL}/${response.data.cover}`
							}
						}))
					setTimeout(() => {
						autoResize(document.querySelectorAll("textarea"))
					}, 500)
				}
			} catch (error) {
				console.log(error)
			}
			setLoading()
		})()
	}, [blogId])

	const submit = async e => {
		try {
			setLoading(true)
			if (e) e.preventDefault()

			const formData = new FormData(e.target)
			formData.append("content", blogData?.content)
			if (coverState?.default?.file) formData.append("cover", coverState.default.file)
			else if (!coverState?.default?.url && coverState?.removed?.uploaded) formData.append("remove_cover", true)
			const response = await axios({
				method: blogId ? "put" : "post",
				url: "/blog/" + (blogId || ""),
				headers: {
					"Authorization": `Bearer ${localStorage.getItem("token")}`,
					"Content-Type": "multipart/form-data"
				},
				data: formData
			})
			if (response.data) return navigate("/blogs")
		} catch (error) {
			console.log(error)
		}
		setLoading()
	}

	const deleteBlog = async () => {
		try {
			setPromptState(prev => ({ ...prev, loading: true }))
			await axios({
				method: "delete",
				url: `/blog/${blogId}`,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`
				}
			})

			navigate("/")
		} catch (error) {
			setPromptState(prev => ({ ...prev, loading: false }))
		}
	}

	const warnDelete = async () => {
		setPromptState({
			heading: "Delete Blog",
			message: `The selected blog will be deleted permanently.`,
			info: blogData?.title,
			Actions: () => (
				<>
					<button className="theme-btn grey" onClick={() => setPromptState(false)}>
						Cancel
					</button>
					<button className="theme-btn danger" onClick={deleteBlog}>
						Delete Category
					</button>
				</>
			)
		})
	}

	const handleCoverSelection = async () => {
		const urls = await selectImage({ imageConfig: imageConfigs.blogCover })
		setCoverState(prev => ({ default: urls?.[0], removed: prev?.default || prev?.removed }))
	}

	const onChange = e => {
		setBlogData(prev => ({
			...prev,
			[e.target.name]: e.target.value
		}))
		autoResize([e.target])
	}

	return (
		<div id="product-form" className="blog-form page-wrapper container-w">
			<div className="container">
				<div>
					<TableTitle title={(blogId ? "Update" : "Create") + " blog"} />
					{blogId && (
						<button className="theme-btn outlined danger flex" onClick={warnDelete}>
							<RiDeleteBinLine /> Delete This Blog
						</button>
					)}
				</div>
				<form onSubmit={submit}>
					<div id="blog-cover-wrapper" className="pointer relative" onClick={handleCoverSelection}>
						{coverState?.default?.url ? (
							<img src={coverState?.default?.url} alt="Blog Cover" />
						) : (
							<div className="cover-placeholder h-full flex">
								<div className="flex col">
									<FaRegImage className="subheading" />
									<span className="font-xs">Upload Cover Image</span>
								</div>
							</div>
						)}
						<div className="cover-actions flex gap-m absolute">
							{coverState?.removed?.uploaded && (
								<button
									type="button"
									className="theme-btn icon"
									onClick={e => {
										e.stopPropagation()
										setCoverState(prev => ({ default: prev.removed }))
									}}
								>
									<LiaUndoAltSolid className="font-l" />
								</button>
							)}
							{coverState?.default?.url && (
								<button
									type="button"
									className="theme-btn icon"
									onClick={e => {
										e.stopPropagation()
										setCoverState(prev => ({ default: null, removed: prev.default }))
									}}
								>
									<MdClose className="font-l" />
								</button>
							)}
						</div>
					</div>
					<div>
						<textarea
							name="title"
							placeholder="Title"
							rows={"1"}
							className="theme-input full master-heading"
							value={blogData?.title}
							onChange={onChange}
							style={{ whiteSpace: "break-spaces" }}
							required
						/>
						<ReactQuill
							theme="snow"
							value={blogData?.content}
							onChange={content => setBlogData(prev => ({ ...prev, content }))}
							placeholder="Content"
							modules={quillConfig}
						/>
						<div className="flex">
							<button type="submit" className="theme-btn primary form-submit">
								{blogId ? "Update" : "Create"} Blog
							</button>
						</div>
					</div>
				</form>
			</div>
			{loading && <Spinner type={spinnerTypes.spinner} {...{ overlay: true }} />}
			{promptState && <Prompt {...promptState} />}
		</div>
	)
}

export default BlogForm
